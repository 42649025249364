// store/campaignCreationStore.ts
import { defineStore } from "pinia";
import type { UseFetchOptions } from "@vueuse/core";
import lodash from "lodash";
import { v4 as uuidv4 } from "uuid";
import { formatBudgetCurrency, parseFormattedCurrency, transformInboundData } from "~/utils";
import useRefreshToken from "~/composables/useRefreshToken";

const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");
const { refreshToken } = useRefreshToken();
const dataInfo: PlatformDataType = {
  id: undefined,
  firstObjective: "",
  secondObjective: { parentObjective: "", objective: "" },
  audienceIds: [],
  budgetAndSchedule: {
    audienceBudgetDistribution: false,
    startDate: null,
    tempStartDateEven: null,
    tempStartDateCustom: null,
    endDate: null,
    tempEndDateEven: null,
    tempEndDateCustom: null,
    budget: "",
    tempBudgetEven: "",
    tempBudgetCustom: "",
    tempBudgetAudienceEven: "",
    tempBudgetAudienceCustom: "",
    pacing: "pace_evenly",
    timePeriods: [],
    tempTimePeriods: [],
    audiencesBudgetEven: [],
  },
  completed: false,
};
const newTimePeriod: Omit<TimePeriodsType, "id"> = {
  startDate: null,
  endDate: null,
  budget: "",
  audiencesBudgetCustom: [],
};

export const useCampaignCreationStore = defineStore("campaignCreationStore", {
  state: (): CampaignState => ({
    step: 0,
    loading: true,
    id: undefined,
    selectedDuplicateId: "",
    brands: [],
    platforms: [],
    audiences: [],
    displayId: "",
    selectedBrand: "",
    selectedCampaignName: "",
    campaignCreation: {},
  }),
  getters: {
    campaignCreationState(state) {
      return {
        brand: state.brands.find((brand) => brand.id === state.selectedBrand)?.name,
        name: state.selectedCampaignName,
        platforms: Object.entries(state.campaignCreation).map(([id, data]) => ({
          id,
          completed: data.completed,
          first_objective: data.firstObjective,
          second_objective: data.secondObjective.objective,
          pacing: data.budgetAndSchedule.pacing,
          budget: data.budgetAndSchedule.budget,
          audience_ids: data.audienceIds,
          audience_budget_distribution: data.budgetAndSchedule.audienceBudgetDistribution,
          audience_budgets_even: data.budgetAndSchedule.audiencesBudgetEven,
          start_date: data.budgetAndSchedule.startDate,
          end_date: data.budgetAndSchedule.endDate,
          time_periods: data.budgetAndSchedule.timePeriods.map((timePeriod) => ({
            id: timePeriod.id,
            start_date: timePeriod.startDate,
            end_date: timePeriod.endDate,
            budget: timePeriod.budget,
            audience_budgets: timePeriod.audiencesBudgetCustom,
          })),
        })),
      };
    },
    isBudgetAudienceDistributionAndPacingCustom(state) {
      return (
        state.campaignCreation[state.displayId].budgetAndSchedule.audienceBudgetDistribution &&
        state.campaignCreation[state.displayId].budgetAndSchedule.pacing === "custom_pacing"
      );
    },
    validStepOne(state) {
      return state.displayId !== "";
    },
    validStepTwo(state) {
      return state.campaignCreation[state.displayId]?.firstObjective !== "";
    },
    validStepThree(state) {
      return state.campaignCreation[state.displayId]?.secondObjective.objective !== "";
    },
    validStepFour(state) {
      return state.campaignCreation[state.displayId]?.audienceIds.length > 0;
    },
    validStepFive(state) {
      const budgetAndSchedule = state.campaignCreation[state.displayId]?.budgetAndSchedule;
      const pacing = budgetAndSchedule?.pacing;
      const audienceBudgetDistribution = budgetAndSchedule?.audienceBudgetDistribution;
      const timePeriods = budgetAndSchedule?.timePeriods;

      if (audienceBudgetDistribution && pacing === "pace_evenly") {
        return (
          budgetAndSchedule.startDate !== null &&
          budgetAndSchedule.endDate !== null &&
          !budgetAndSchedule.audiencesBudgetEven.includes("") &&
          budgetAndSchedule.audiencesBudgetEven.length > 0
        );
      } else if (!audienceBudgetDistribution && pacing === "pace_evenly") {
        return (
          budgetAndSchedule.tempBudgetEven !== "" &&
          budgetAndSchedule.startDate !== null &&
          budgetAndSchedule.endDate !== null
        );
      } else if (!audienceBudgetDistribution && pacing === "custom_pacing") {
        return !timePeriods.some(
          (period) => period.budget === "" || period.startDate === null || period.endDate === null,
        );
      } else if (audienceBudgetDistribution && pacing === "custom_pacing") {
        return !timePeriods.some(
          (period) =>
            period.startDate === null ||
            period.endDate === null ||
            period.audiencesBudgetCustom.includes("") ||
            period.audiencesBudgetCustom.length === 0,
        );
      }
      return false;
    },
    firstSteps(state) {
      return state.step === 1 || state.step === 2 || state.step === 3;
    },
    disableNextButton() {
      if (this.step === 1) {
        return !this.validStepOne;
      } else if (this.step === 2) {
        return !this.validStepTwo;
      } else if (this.step === 3) {
        return !this.validStepThree;
      } else if (this.step === 4) {
        return !this.validStepFour;
      } else if (this.step === 5) {
        return !this.validStepFive;
      } else {
        return true;
      }
    },
  },
  actions: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setStep(step: number) {
      this.step = step;
      if (step === 6) {
        this.campaignCreation[this.displayId].completed = true;
      }
    },
    setStepBack() {
      this.step--;
    },
    setStepNext() {
      this.step++;
      const currentStep = this.step;
      if (currentStep === 6) {
        this.campaignCreation[this.displayId].completed = true;
      }
      if (currentStep === 7) {
        this.resetCampaignCreation();
      }
    },
    handlePlatform(platformId: string) {
      this.displayId = platformId;
      if (!this.campaignCreation[platformId]) {
        this.campaignCreation[platformId] = lodash.cloneDeep(dataInfo);
      }
    },
    clearDisplayId() {
      this.displayId = "";
    },
    handleSetSelectedBrand(brandId: string) {
      this.selectedBrand = brandId;
    },
    handleFirstObjective(firstObjective: string) {
      this.campaignCreation[this.displayId].firstObjective = firstObjective;
      if (this.campaignCreation[this.displayId].secondObjective.parentObjective === "") {
        this.campaignCreation[this.displayId].secondObjective.parentObjective = firstObjective;
      } else if (
        this.campaignCreation[this.displayId].secondObjective.parentObjective !==
        this.campaignCreation[this.displayId].firstObjective
      ) {
        this.campaignCreation[this.displayId].secondObjective.parentObjective = firstObjective;
        this.campaignCreation[this.displayId].secondObjective.objective = "";
      }
    },
    handleAudienceBudgetDistribution(audienceBudgetDistribution: boolean) {
      this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution = audienceBudgetDistribution;

      if (audienceBudgetDistribution) {
        if (this.campaignCreation[this.displayId].budgetAndSchedule.pacing === "pace_evenly") {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceEven;
        } else {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceCustom;
        }
      } else if (this.campaignCreation[this.displayId].budgetAndSchedule.pacing === "pace_evenly") {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetEven;
      } else {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetCustom;
      }
    },
    handleBudget(budget: string) {
      this.campaignCreation[this.displayId].budgetAndSchedule.budget = budget;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetEven = budget;
    },
    handleStartDate(startDate: Date) {
      this.campaignCreation[this.displayId].budgetAndSchedule.startDate = startDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateEven = startDate;
    },
    handleEndDate(endDate: Date) {
      this.campaignCreation[this.displayId].budgetAndSchedule.endDate = endDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateEven = endDate;
    },
    handlePacing(pacing: string) {
      this.campaignCreation[this.displayId].budgetAndSchedule.pacing = pacing;
      if (pacing === "custom_pacing") {
        // Restore custom time periods if they exist
        if (this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods.length > 0) {
          this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [
            ...this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods,
          ];
        } else {
          this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [{ ...newTimePeriod, id: uuidv4() }];
          this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = [
            { ...newTimePeriod, id: uuidv4() },
          ];
        }
        // Restore budget based on audienceBudgetDistribution
        if (this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution) {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceCustom;
        } else {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetCustom;
        }
        // Restore start date if it exists
        this.campaignCreation[this.displayId].budgetAndSchedule.startDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateCustom;
        // Restore end date if it exists
        this.campaignCreation[this.displayId].budgetAndSchedule.endDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateCustom;
      } else if (pacing === "pace_evenly") {
        // Save current custom time periods to temporary storage
        this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = [
          ...this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods,
        ];
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [];
        // Restore budget based on audienceBudgetDistribution
        if (this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution) {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceEven;
        } else {
          this.campaignCreation[this.displayId].budgetAndSchedule.budget =
            this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetEven;
        }
        // Restore start date if it exists
        this.campaignCreation[this.displayId].budgetAndSchedule.startDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateEven;
        // Restore end date if it exists
        this.campaignCreation[this.displayId].budgetAndSchedule.endDate =
          this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateEven;
      }
    },
    handleAddCustomTimePeriod() {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [
        ...this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods!,
        { ...newTimePeriod, id: uuidv4() },
      ];
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods = [
        ...this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods!,
        { ...newTimePeriod, id: uuidv4() },
      ];
    },
    handleDeleteCustomTimePeriod(index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = this.campaignCreation[
        this.displayId
      ].budgetAndSchedule.timePeriods!.filter((_, i) => i !== index);
    },
    handleTimePeriodBudget(budget: string, index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[index].budget = budget;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods[index].budget = budget;
      const accumulatedBudget = formatBudgetCurrency(
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods
          .reduce((acc, item) => {
            return acc + parseFormattedCurrency(item.budget);
          }, 0)
          .toString(),
      );
      this.campaignCreation[this.displayId].budgetAndSchedule.budget = accumulatedBudget;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetCustom = accumulatedBudget;
    },
    handleTimePeriodStartDate(startDate: Date, index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[index].startDate = startDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods[index].startDate = startDate;
      const firstDate = this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[0].startDate ?? new Date();
      this.campaignCreation[this.displayId].budgetAndSchedule.startDate = firstDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempStartDateCustom = firstDate;
    },
    handleTimePeriodEndDate(endDate: Date, index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[index].endDate = endDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempTimePeriods[index].endDate = endDate;
      const lastDate =
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods[
          this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods.length - 1
        ].endDate ?? new Date();
      this.campaignCreation[this.displayId].budgetAndSchedule.endDate = lastDate;
      this.campaignCreation[this.displayId].budgetAndSchedule.tempEndDateCustom = lastDate;
    },
    handleBudgetAudienceEven(budget: string, index: number) {
      this.campaignCreation[this.displayId].budgetAndSchedule.audiencesBudgetEven[index] = budget;

      const accumulatedBudget = formatBudgetCurrency(
        this.campaignCreation[this.displayId].budgetAndSchedule.audiencesBudgetEven
          .reduce((acc, item) => {
            return acc + parseFormattedCurrency(item);
          }, 0)
          .toString(),
      );
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceEven = accumulatedBudget;

      // Update the main budget based on audienceBudgetDistribution
      const pacing = this.campaignCreation[this.displayId].budgetAndSchedule.pacing;
      if (
        this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution &&
        pacing === "pace_evenly"
      ) {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget = accumulatedBudget;
      }
    },
    handleBudgetAudienceCustom(budget: string, timePeriodIndex: number, audienceIndex: number) {
      const timePeriods = this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods;
      // Ensure reactivity
      const updatedTimePeriod = {
        ...timePeriods[timePeriodIndex],
        audiencesBudgetCustom: [
          ...timePeriods[timePeriodIndex].audiencesBudgetCustom.slice(0, audienceIndex),
          budget,
          ...timePeriods[timePeriodIndex].audiencesBudgetCustom.slice(audienceIndex + 1),
        ],
      };
      this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods = [
        ...timePeriods.slice(0, timePeriodIndex),
        updatedTimePeriod,
        ...timePeriods.slice(timePeriodIndex + 1),
      ];

      const accumulatedBudget = formatBudgetCurrency(
        this.campaignCreation[this.displayId].budgetAndSchedule.timePeriods
          .reduce((acc, item) => {
            return acc + item.audiencesBudgetCustom.reduce((acc, item) => acc + parseFormattedCurrency(item), 0);
          }, 0)
          .toString(),
      );
      this.campaignCreation[this.displayId].budgetAndSchedule.tempBudgetAudienceCustom = accumulatedBudget;

      // Update the main budget based on audienceBudgetDistribution
      const pacing = this.campaignCreation[this.displayId].budgetAndSchedule.pacing;
      if (
        this.campaignCreation[this.displayId].budgetAndSchedule.audienceBudgetDistribution &&
        pacing === "custom_pacing"
      ) {
        this.campaignCreation[this.displayId].budgetAndSchedule.budget = accumulatedBudget;
      }
    },
    deletePlatform(platformId: string) {
      delete this.campaignCreation[platformId];
      if (Object.keys(this.campaignCreation).length === 0) {
        this.campaignCreation = {};
        this.displayId = "";
        this.selectedBrand = "";
        this.selectedCampaignName = "";
        this.step = 1;
      } else {
        this.displayId = Object.keys(this.campaignCreation)[Object.keys(this.campaignCreation).length - 1];
      }
    },
    resetCampaignCreation() {
      this.campaignCreation = {};
      this.displayId = "";
      this.selectedBrand = "";
      this.selectedCampaignName = "";
    },
    handleNavigateToFirstObjective(displayId: string) {
      this.displayId = displayId;
      this.setStep(2);
    },
    handleNavigateToSecondObjective(displayId: string) {
      this.displayId = displayId;
      this.setStep(3);
    },
    handleNavigateToAudiences(displayId: string) {
      this.displayId = displayId;
      this.setStep(4);
    },
    handleNavigateToBudgetAndSchedule(displayId: string) {
      this.displayId = displayId;
      this.setStep(5);
    },
    async getOptions() {
      if (this.brands.length === 0 || this.platforms.length === 0 || this.audiences.length === 0) {
        const bodyOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.value.access}`,
          },
          body: {
            reports: false,
            list_platforms: true,
          },
        };
        const platformBodyOptions = { ...bodyOptions, body: { ...bodyOptions.body, status: "active" } };
        const [brands, platforms, audiences] = await Promise.all([
          useFetch<DropdownSelectionResponse>(`${BASE_URL}/api/v2/brands/`, bodyOptions as UseFetchOptions),
          useFetch<{ results: PlatformType[] }>(
            `${BASE_URL}/api/v2/platforms/`,
            platformBodyOptions as UseFetchOptions,
          ),
          useFetch<AudienceType[]>(`${BASE_URL}/api/v1/reports/audiences/`, {
            headers: bodyOptions.headers,
          }),
        ]);
        if (
          brands.error.value?.statusCode === 401 ||
          platforms.error.value?.statusCode === 401 ||
          audiences.error.value?.statusCode === 401
        ) {
          await refreshToken();
        } else if (brands.data.value && platforms.data.value && audiences.data.value) {
          this.brands = brands.data.value.results
            ? Object.entries(brands.data.value.results)
                .map(([id, name]) => ({ id, name }))
                .sort((a, b) => a.name.localeCompare(b.name))
            : [];
          this.platforms = platforms.data.value.results ?? [];
          this.audiences = audiences.data.value ?? [];
        }
      }
    },
    async getExistingData(id: string, isDuplicate = false) {
      const bodyOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
      };
      const { data: campaignData, error: campaignError } = await useFetch<CampaignResponseType>(
        `${BASE_URL}/api/v2/campaigns/${id}`,
        bodyOptions as UseFetchOptions,
      );
      if (campaignError.value?.statusCode === 401) {
        await refreshToken();
      } else if (campaignError.value?.statusCode === 404) {
        navigateTo("/campaigns/");
      } else if (campaignData.value) {
        await this.setCampaignState(campaignData.value, isDuplicate);
      }
    },
    async setCampaignState(inboundData: CampaignResponseType, isDuplicate = false) {
      // Ensure brands and platforms are loaded first
      await this.getOptions();

      // Transform and set the rest of the state
      const transformedData = transformInboundData(inboundData, isDuplicate);
      this.id = transformedData.id;
      this.selectedBrand = transformedData.selectedBrand;
      this.selectedCampaignName = transformedData.selectedCampaignName;
      this.campaignCreation = transformedData.campaignCreation;
      this.step = transformedData.step;
      this.displayId = transformedData.displayId;
      this.loading = transformedData.loading;
    },
  },
});
